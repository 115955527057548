import {
  UPDATE_BOOK_STATE,
  RESET_BOOK_STATE,
  SELECT_BEACH,
  SET_BOOK_STATE_FOR_ADDITIONAL_ORDER
} from './types';

const filter = 'book';

export const updateBookState = payload => ({
  filter,
  type: UPDATE_BOOK_STATE,
  payload
});

export const selectBeach = beach => ({
  filter,
  type: SELECT_BEACH,
  payload: beach
})

export const setBookStateForAdditionalOrder = payload => ({
  filter,
  type: SET_BOOK_STATE_FOR_ADDITIONAL_ORDER,
  payload
});

export const resetBookState = () => ({
  filter,
  type: RESET_BOOK_STATE
});
