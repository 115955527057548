import React from "react";

export default class PrivacyPolicy extends React.Component {
  render() {
    return (
      <main>
        <section id="privacyPolicy">
          <h1>Privacy Policy</h1>
          <strong>Privacy Notice</strong>This privacy notice discloses the
          privacy practices for www.mybeachbutler.com. This privacy notice
          applies solely to information collected by this website. It will
          notify you of the following:
          <ol>
            <li>
              What personally identifiable information is collected from you
              through the website, how it is used and with whom it may be
              shared.
            </li>
            <li>
              What choices are available to you regarding the use of your data.
            </li>
            <li>
              The security procedures in place to protect the misuse of your
              information.
            </li>
            <li>How you can correct any inaccuracies in the information.</li>
          </ol>
          <strong>Information Collection, Use, and Sharing</strong>
          We are the sole owners of the information collected on this site. We
          only have access to/collect information that you voluntarily give us
          via email or other direct contact from you. We will not sell or rent
          this information to anyone. We will use your information to respond to
          you, regarding the reason you contacted us. We will not share your
          information with any third party outside of our organization, other
          than as necessary to fulfill your request, e.g. to ship an order.
          Unless you ask us not to, we may contact you via email in the future
          to tell you about specials, new products or services, or changes to
          this privacy policy.
          <strong>Your Access to and Control Over Information</strong>You may
          opt out of any future contacts from us at any time. You can do the
          following at any time by contacting us via email at
          info@mybeachbutler.com:
          <ul>
            <li>See what data we have about you, if any.</li>
            <li>Change/correct any data we have about you.</li>
            <li>Have us delete any data we have about you.</li>
            <li>Express any concern you have about our use of your data.</li>
          </ul>
          <strong>Security</strong>We take precautions to protect your
          information. When you submit sensitive information via the website,
          your information is protected both online and offline. Wherever we
          collect sensitive information (such as credit card data), that
          information is encrypted and transmitted to us in a secure way. You
          can verify this by looking for a lock icon in the address bar and
          looking for "https" at the beginning of the address of the Web page.
          While we use encryption to protect sensitive information transmitted
          online, we also protect your information offline. Only employees who
          need the information to perform a specific job (for example, billing
          or customer service) are granted access to personally identifiable
          information. The computers/servers in which we store personally
          identifiable information are kept in a secure environment.
          <strong>Orders</strong>We request information from you on our order
          form. To buy from us, you must provide contact information (like name
          and address) and financial information (like credit card number,
          expiration date). This information is used for billing purposes and to
          fill your orders. If we have trouble processing an order, we'll use
          this information to contact you.
          <strong>
            If you feel that we are not abiding by this privacy policy, you
            should contact us immediately at info@mybeachbutler.com.
          </strong>
        </section>
      </main>
    );
  }
}
