import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import { showInfo, closeModal } from "actions/modalActions";
import { updateBookState } from "actions/bookActions";
import AdditionalEquipment from "./additional-equipment/additional-equipment";
import API from "api";

class Equipment extends Component {
  state = {
    price: 0,
    inventory: [],
    availableInventory: {},
    booked: [],
    showAdditionalModal: false,
    additionalEquip: null
  };

  async componentDidMount() {
    const {
      book: { date, bookedEquipment },
      changeStep,
      showInfo
    } = this.props;

    if (!date) {
      // return to first step
      return showInfo({
        html: "You should pick date firstly",
        cb: () => changeStep(1)
      });
    }

    const inventory = await API.inventory.fetchList();

    this.setState(
      {
        inventory,
        booked: [...(bookedEquipment || [])]
      },
      this.getAvailableEquipment
    );
  }

  getAvailableEquipment = async () => {
    const {
      book: { beach, date },
      showInfo
    } = this.props;

    const availableInventory = await API.beaches.getAvailableInventoryForBeachByDate(
      beach._id,
      date
    );

    if (!availableInventory) {
      showInfo({
        html: "Error getting status of available inventory. Please reload page"
      });
    }

    this.setState(
      { availableInventory: availableInventory || {} },
      this.checkIfAllItemsAreStillAvailable
    );
  };

  checkIfAllItemsAreStillAvailable = cb => {
    const {
      showInfo,
      book: { date, beach },
      updateBookState
    } = this.props;
    const { booked, inventory } = this.state;

    if (!booked || !booked.length) return;

    API.beaches
      .checkIfAvailableForBook(beach._id, date, booked)
      .then(result => {
        if (!result) {
          showInfo({
            html: (
              <div>
                Something goes wrong. <br /> Booked equipment will be reseted,{" "}
                <br />
                please reload page and choose again
              </div>
            )
          });

          // Reset booked equipment
          updateBookState({ bookedEquipment: [], paymentInfo: {} });
          this.setState({ booked: [] });
          cb && cb(false);
        }

        const { availableInventory, notEnough } = result;

        this.setState({
          availableInventory
        });

        if (notEnough.length) {
          showInfo({
            html: (
              <div>
                <p>
                  We are sorry, some of equipment are booked already. <br />{" "}
                  Please decrease quantity of:
                </p>

                <ul>
                  {notEnough.map(equip => {
                    const { _id, quantity } = equip;
                    const item = _.find(inventory, { _id });

                    return (
                      <li key={_id}>
                        {item.name} by {quantity}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )
          });

          cb && cb(false);
        } else {
          cb && cb(true);
        }
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.booked !== this.state.booked) {
      this.updatePrice();
    }
  }

  componentWillUnmount() {
    this.props.closeModal();
  }

  updatePrice = () => {
    const { booked, inventory } = this.state;

    const price = _.reduce(
      booked,
      (accum, booked) => {
        const item = _.find(inventory, { _id: booked._id });
        return accum + (item.price || 0) * booked.quantity;
      },
      0
    );

    this.setState({ price });
  };

  changeQuantity = (equipmentId, diff) => {
    const { showInfo } = this.props;
    const { booked, availableInventory, inventory } = this.state;

    const bookedItem = _.find(booked, { _id: equipmentId }) || {
      _id: equipmentId,
      quantity: 0
    };

    const previousQty = bookedItem.quantity;

    const newCount = previousQty + diff;

    const withoutCurrent = _.reject(booked, { _id: equipmentId });

    if (newCount <= 0) {
      // Check if has related items
      const relatedIDs = _.filter(inventory, { relatedTo: equipmentId }).map(
        i => i._id
      );

      const withoutCurrentAndRelated = _.filter(
        withoutCurrent,
        item => !relatedIDs.includes(item._id)
      );

      return this.setState({ booked: withoutCurrentAndRelated });
    }

    let available = availableInventory[equipmentId] || 0;

    if (newCount > available && newCount > previousQty) {
      return showInfo({
        html:
          available !== 0 && available !== "0" ? (
            <span>
              Maximum available for today is: <b>{available}</b>
            </span>
          ) : (
            <span>
              We are sorry, we are sold out of this item today. Please try a
              different item or a different day.
            </span>
          )
      });
    }

    this.setState({
      booked: [...withoutCurrent, { ...bookedItem, quantity: newCount }]
    });
  };

  triggerAdditionalModal = equip => {
    const currentQty = this.getCurrentQtyOfEquip(equip._id);

    this.setState({
      showAdditionalModal: true,
      additionalEquip: equip,
      additionalEquipInitialValue: currentQty
    });
  };

  getCurrentQtyOfEquip = _id => {
    const { booked } = this.state;
    const current = _.find(booked, { _id });
    const currentQty = (current && current.quantity) || 0;
    return currentQty;
  };

  cancelAdditionalModal = () => {
    const { additionalEquipInitialValue, additionalEquip } = this.state;
    const currentQty = this.getCurrentQtyOfEquip(additionalEquip._id);

    this.changeQuantity(
      additionalEquip._id,
      additionalEquipInitialValue - currentQty
    );
    this.closeAdditionalModal();
  };

  closeAdditionalModal = () => {
    this.setState({
      showAdditionalModal: false,
      additionalEquip: null,
      additionalEquipInitialValue: 0
    });
  };

  goNext = () => {
    const { updateBookState, changeStep } = this.props;
    const { booked } = this.state;

    // Check if inventory is still available
    this.checkIfAllItemsAreStillAvailable(isAvailable => {
      if (isAvailable) {
        updateBookState({ bookedEquipment: booked, paymentInfo: {} });
        changeStep(4);
      }
    });
  };

  render() {
    const { changeStep } = this.props;
    const { showAdditionalModal, additionalEquip, booked, price } = this.state;

    return (
      <>
        <section className="equipment">
          <h2 className="booking__title">Choose Beach Butler Equipment</h2>
          <div className="booking__description booking__description--center">
            <p>All prices include setup, delivery and pick up.</p>
          </div>

          {this.renderInventory()}
        </section>

        {showAdditionalModal && (
          <AdditionalEquipment
            booked={booked}
            item={additionalEquip}
            changeQuantity={this.changeQuantity}
            okHandler={this.closeAdditionalModal}
            closeHandler={this.cancelAdditionalModal}
          />
        )}

        <div className="booking-controls">
          <div className="booking-controls__inner">
            <button
              className="booking-controls__back"
              onClick={() => {
                changeStep(2);
              }}
            >
              Back to Map
            </button>

            <div className="booking-controls__cart">
              <div className="booking-controls__cart-icon">
                <span>{booked.length}</span>
              </div>
              <span>total sum</span>${price || 0}
            </div>

            <button
              className="booking-controls__submit"
              onClick={this.goNext}
              disabled={price <= 0}
            >
              Next
            </button>
          </div>
        </div>
      </>
    );
  }

  renderInventory = () => {
    const { booked, inventory } = this.state;

    return (
      <ul className="equipment__list">
        {inventory.map(item => {
          if (item.relatedTo) return null;

          const relatedItems = _.filter(inventory, { relatedTo: item._id });

          const bookedItem = _.find(booked, { _id: item._id });
          const bookedCount = (bookedItem && bookedItem.quantity) || 0;

          return (
            <li className="equipment__item" key={item._id}>
              <img
                className="equipment__item-img"
                src={`${process.env.REACT_APP_API_URL}/equipment-images/${item.image}`}
                alt=""
              />

              <b className="equipment__item-name">{item.name}</b>

              <div className="equipment__item-footer">
                <span className="equipment__item-price">
                  ${item.price} per day
                </span>

                <div className="equipment__item-quantity">
                  <button onClick={() => this.changeQuantity(item._id, -1)}>
                    -
                  </button>

                  <span>{bookedCount}</span>

                  <button onClick={() => this.changeQuantity(item._id, +1)}>
                    +
                  </button>
                </div>
              </div>

              {!!bookedCount && !!relatedItems.length && (
                <div className="equipment__item-additional">
                  {relatedItems.map(additionalItem => (
                    <button
                      key={additionalItem._id}
                      onClick={() =>
                        this.triggerAdditionalModal(additionalItem)
                      }
                    >
                      + {additionalItem.name}
                    </button>
                  ))}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    );
  };
}

Equipment.propTypes = {
  book: PropTypes.object.isRequired,
  updateBookState: PropTypes.func.isRequired,
  showInfo: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

const mapStateToProps = ({ book }) => ({
  book
});

export default connect(mapStateToProps, {
  updateBookState,
  showInfo,
  closeModal
})(Equipment);
