import moment from 'moment';

export default time => {
  let hours = moment.duration(time).hours();
  let minutes = moment.duration(time).minutes();

  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}:${minutes} ${ampm}`;
};
