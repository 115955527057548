import React from "react";
import { Link } from "react-router-dom";
import API from "api";

export default class Equipment extends React.Component {
  state = {
    list: null,
  };

  componentDidMount() {
    API.inventory.fetchList().then((list) => {
      this.setState({ list });
    });
  }

  render() {
    const { list } = this.state;

    return (
      <main className="pricing-page">
        <p className="pricing-page__lead">
          All prices include delivery, setup and pick up.
        </p>

        <ul className="pricing-page__list">
          {list &&
            list.map((equip) => {
              let price = `${equip.price} per day`;

              if (equip.name.toLowerCase() === "ice") {
                price = `${equip.price} per pack`;
              }

              return (
                <li className="pricing-page__item" key={equip._id}>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/equipment-images/${equip.image}`}
                    alt=""
                  />
                  <div className="pricing-page__item-inner">
                    <strong>{equip.name}</strong>
                    <label>${price}</label>
                    <span>{equip.description}</span>
                    <Link to="/book">Book</Link>

                    {/* <button disabled>Not Currently Available</button> */}
                  </div>
                </li>
              );
            })}
        </ul>
      </main>
    );
  }
}
