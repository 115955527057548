import React from 'react';
// import { css } from '@emotion/core';
import MoonLoader from 'react-spinners/MoonLoader';

// const override = css`
//   margin: 0 auto;
//   border-color: red;
// `;

const overlayStyles = {
  position: 'fixed',
  backgroundColor: 'rgba(255,255,255, 0.5)',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 200
};

const Spinner = ({ withOverlay, loading, ...anotherProps }) => {
  return (
    loading && (
      <div style={withOverlay ? overlayStyles : {}}>
        <div
          style={{
            boxSizing: 'content-box',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <MoonLoader
            // css={override}
            color={'#27a9e0'}
            loading={loading}
            {...anotherProps}
          />
        </div>
      </div>
    )
  );
};

export default Spinner;
