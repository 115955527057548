import axios from "axios";

const apiPoint = "/api/beaches/";

export default {
  fetchList() {
    return axios
      .get(apiPoint)
      .then((res) => Promise.resolve(res.data))
      .catch((err) => {
        console.log(err);
        return Promise.resolve([]);
      });
  },
  getById(id) {
    return axios
      .get(apiPoint + id)
      .then((res) => Promise.resolve(res.data))
      .catch((err) => {
        console.log(err);
        return Promise.resolve(null);
      });
  },
  getAvailableDays(id) {
    return this.getById(id).then((beach) => beach && beach.datesAndTimes);
  },
  getBeachPolygons(id) {
    return this.getById(id).then((beach) => beach && beach.polygons);
  },
  getTax(id) {
    return this.getById(id).then((beach) => beach && beach.tax);
  },
  getAvailableInventoryForBeachByDate(beachId, date) {
    return axios
      .get(
        `${apiPoint}get-available-inventory-for-date/${beachId}?date=${date}`
      )
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        return null;
      });
  },
  checkIfAvailableForBook(beachId, date, tryingToBook) {
    const data = { beachId, date, tryingToBook };

    return axios
      .post(`${apiPoint}check-if-available-for-book`, data)
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        return null;
      });
  },
};
