import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import axios from "axios";
import dropin from "braintree-web-drop-in";

import API from "api";
import { Spinner } from "components";
import { updateBookState } from "actions/bookActions";
import { showPrompt, showInfo } from "actions/modalActions";

class Payment extends Component {
  state = {
    paymentBoxReady: false,

    processing: false,
    modalText: "Wait, processing your reservation..",
    showModalClose: false
  };

  componentDidMount() {
    const {
      book: { beach }
    } = this.props;

    this.createDropInUI();

    API.beaches.getTax(beach._id).then(beachTax => {
      this.setState({ beachTax }, this.calculateTotal);
    });
  }

  componentDidUpdate(prevProps) {
    const { paymentInfo } = this.props.book;

    if (prevProps.book.paymentInfo !== paymentInfo) {
      this.calculateTotal();
    }
  }

  calculateTotal = () => {
    const { beachTax } = this.state;
    const {
      updateBookState,
      book: { paymentInfo }
    } = this.props;

    const { amount, discountValue, totalAmount, withoutTax } = paymentInfo;

    const discountAmount = discountValue
      ? parseFloat((amount * (discountValue / 100)).toFixed(2))
      : 0;
    const newWithoutTax = parseFloat((amount - discountAmount).toFixed(2));
    const taxAmount = parseFloat((newWithoutTax * (beachTax / 100)).toFixed(2));
    const newTotalAmount = parseFloat((newWithoutTax + taxAmount).toFixed(2));

    if (newTotalAmount !== totalAmount || withoutTax !== newWithoutTax) {
      const newPaymentInfo = {
        ...paymentInfo,
        discountAmount,
        taxAmount,
        withoutTax: newWithoutTax,
        totalAmount: newTotalAmount
      };

      updateBookState({ paymentInfo: newPaymentInfo });
    }
  };

  addDiscount = e => {
    e.preventDefault();
    const { showPrompt, updateBookState, book, showInfo } = this.props;

    showPrompt({
      html: `Enter promo code:`,
      underInputHtml: `<small>Code is case sensitive</small>`
    }).then(code => {
      if (!code || !code.trim().length) {
        return;
      }

      // Check Discount Code
      axios
        .get(`/api/discounts/check-discount/${code.trim()}`)
        .then(res => {
          const { data: discount } = res;

          updateBookState({
            paymentInfo: {
              ...book.paymentInfo,
              discountId: discount._id,
              discountCode: discount.code,
              discountValue: discount.value
            }
          });
        })
        .catch(err => {
          const {
            response: { data }
          } = err;

          showInfo({
            isError: true,
            html: (
              <p style={{ textAlign: "center" }}>
                {data && data.message ? data.message : "Server Error"}
              </p>
            )
          });
          console.log(err);
        });
    });
  };

  deleteDiscount = e => {
    e && e.preventDefault && e.preventDefault();

    const { updateBookState, book } = this.props;

    const paymentInfo = { ...book.paymentInfo };
    delete paymentInfo.discountId;
    delete paymentInfo.discountCode;
    delete paymentInfo.discountValue;
    updateBookState({ paymentInfo });
  };

  createDropInUI = () => {
    // if (this.braintreeInstance) {
    //   this.braintreeInstance.teardown();
    // }

    axios
      .get("/api/reservations/get-braintree-token")
      .then(res => {
        const { data: token } = res;
        dropin.create(
          {
            authorization: token,
            container: "#dropin-container",
            paypal: {
              flow: 'vault'
            },
            card: {
              overrides: {
                fields: {
                  cvv: {
                    // type: 'password',
                    maskInput: true
                  }
                }
              }
            }
          },
          (createErr, instance) => {
            if (createErr) {
              console.log(createErr);
              return;
              // return showInfo({
              //   html: "Error while creating payment container"
              // });
            }

            this.braintreeInstance = instance;
            this.setState({ paymentBoxReady: true });
          }
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  confirmReservation = e => {
    e && e.preventDefault && e.preventDefault();

    const { book, showInfo, changeStep, updateBookState } = this.props;

    this.braintreeInstance.requestPaymentMethod((err, payload) => {
      if (err) {
        console.log("err", err);
        return;
      }

      this.setState({ processing: true });

      axios
        .post(
          "/api/reservations/create-reservation",
          {
            book,
            paymentMethodNonce: payload.nonce
          },
          { timeout: 45000 }
        )
        .then(res => {
          this.braintreeInstance.teardown();
          this.setState({ processing: false });

          if (res.data.success) {
            // Update Book State
            updateBookState(res.data.reservation);
            // Go to Thank You Page
            changeStep(7);
          }
        })
        .catch(err => {
          this.braintreeInstance.teardown();
          this.setState({ processing: false });

          this.createDropInUI();

          const message =
            (err.response && err.response.data && err.response.data.message) ||
            "Something goes wrong. Please try again";

          showInfo({ html: message, isError: true });
        });
    });
  };

  render() {
    const { paymentBoxReady, processing } = this.state;

    const {
      book: { paymentInfo },
      changeStep
    } = this.props;

    const {
      amount,
      discountCode,
      withoutTax,
      totalAmount,
      discountAmount,
      taxAmount,
      discountValue,
      discountId
    } = paymentInfo;

    return (
      <>
        <Spinner loading={!paymentBoxReady || processing} withOverlay />
        
        <section
          className="payment"
          style={{ opacity: paymentBoxReady ? "1" : "0" }}
        >
          <h2 className="booking__title">Payment</h2>

          <table className="payment__table">
            <tbody>
              <tr>
                <th>Equipment</th>
                <td>${amount.toFixed(2)}</td>
              </tr>
              <tr>
                <th>
                  Promo Code{" "}
                  {discountId ? (
                    <>
                      <small>
                        ({discountCode} - {discountValue}%)
                      </small>
                      <button
                        className="payment__remove-discount"
                        onClick={this.deleteDiscount}
                        title="Remove Discount"
                      >
                        &#10008;
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                </th>
                <td>
                  {discountId && discountAmount ? (
                    `- $${discountAmount.toFixed(2)}`
                  ) : (
                    <button
                      className="payment__add-btn"
                      onClick={this.addDiscount}
                    >
                      Add
                    </button>
                  )}
                </td>
              </tr>
              <tr>
                <th>Subtotal</th>
                <td>${withoutTax && withoutTax.toFixed(2)}</td>
              </tr>
              <tr>
                <th>Tax</th>
                <td>${taxAmount && taxAmount.toFixed(2)}</td>
              </tr>
              <tr>
                <th>Total</th>
                <td>${totalAmount && totalAmount.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>

          <div id="dropin-container"></div>
        </section>

        <div className="booking-controls">
          <div className="booking-controls__inner">
            <button
              className="booking-controls__back"
              onClick={() => {
                changeStep(5);
              }}
            >
              Back to Summary
            </button>
            <button
              className="booking-controls__submit"
              onClick={() => {
                this.confirmReservation();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </>
    );
  }
}

Payment.propTypes = {
  book: PropTypes.object.isRequired,
  updateBookState: PropTypes.func.isRequired,
  showPrompt: PropTypes.func.isRequired
};

const mapStateToProps = ({ book }) => ({
  book
});

export default compose(
  connect(mapStateToProps, { updateBookState, showPrompt, showInfo })
)(Payment);
