import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import DayPicker from "react-day-picker";
import moment from "moment";

import { generateTimeIntervalOptions, getMSFromTimeString } from "utils";
import { showInfo } from "actions/modalActions";
import { updateBookState } from "actions/bookActions";
import API from "api";

const timeInterval = 30 * 60 * 1000; // 30 minutes
const getDate = string => moment(string, "MM/DD/YYYY").toDate();

class DateTime extends Component {
  state = {
    timeOptions: [],
    availableDays: []
  };

  componentDidMount() {
    // Disable booking until today or tomorrow if today's time already past 14 o'clock
    const today = moment()
      .startOf("day")
      .toDate();

    const tomorrow = moment()
      .startOf("day")
      .add(1, "days")
      .toDate();

    const hoursNow = moment().hours();

    const disabledDays = [
      {
        before:
          hoursNow >= 14 // Disable booking same day after 14:00
            ? tomorrow
            : today
      }
    ];

    const {
      book: { beach, date: selectedDate },
      changeStep,
      showInfo
    } = this.props;

    if (!beach) {
      return showInfo({
        html: "You should select a beach firstly",
        cb: () => changeStep(0)
      });
    }

    // Get beach available days
    API.beaches.getAvailableDays(beach._id).then(datesAndTimes => {
      const filteredDatesAndTimesSets = datesAndTimes
        ? datesAndTimes.filter(({ from, to, arrival, departure }) => {
            return from && to && arrival && departure;
          })
        : [];

      if (!filteredDatesAndTimesSets.length) {
        return showInfo({
          html:
            "We are sorry, there are no available dates to reserve on this beach"
        });
      }

      const availableDays = filteredDatesAndTimesSets.map(set => ({
        ...set,
        from: getDate(set.from),
        to: getDate(set.to)
      }));

      const initialMonth =
        selectedDate && moment(selectedDate, "MM/DD/YYYY").toDate();

      this.setState(
        {
          availableDays,
          disabledDays,
          initialMonth
        },
        this.setTimeOptionsForSelectedDate
      );
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.book.date !== this.props.book.date) {
      this.setTimeOptionsForSelectedDate();
    }
  }

  setTimeOptionsForSelectedDate = () => {
    const { availableDays } = this.state;
    const { book } = this.props;

    const dateObject = getDate(book.date);

    const currentSet = _.find(availableDays, set => {
      return dateObject >= set.from && dateObject <= set.to;
    });

    if (!currentSet) {
      return this.setState({ timeOptions: [] }, this.resetTime);
    }

    const isSelectedDateIsToday =
      dateObject.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);

    const currentTimeInMS = Date.now() - new Date().setHours(0, 0, 0, 0);
    const roundedCurrentTime =
      currentTimeInMS - (currentTimeInMS % timeInterval) + timeInterval;

    console.log("currentTimeInMS", currentTimeInMS);
    console.log("roundedCurrentTime", roundedCurrentTime);

    const arrivalTimeInMS = getMSFromTimeString(currentSet.arrival);
    const departureTimeInMS = getMSFromTimeString(currentSet.departure);

    console.log("isSelectedDateIsToday", isSelectedDateIsToday);

    const timeOptions = generateTimeIntervalOptions(
      isSelectedDateIsToday
        ? Math.max(roundedCurrentTime, arrivalTimeInMS)
        : arrivalTimeInMS,
      departureTimeInMS,
      timeInterval
    );

    this.setState({ timeOptions });

    // Check if current selected times are included in the available time options
    if (
      (book.arrivalTime &&
        getMSFromTimeString(book.arrivalTime) < arrivalTimeInMS) ||
      (book.departureTime &&
        getMSFromTimeString(book.departureTime) > departureTimeInMS)
    ) {
      return this.resetTime();
    }
  };

  resetTime = () => {
    this.props.updateBookState({ arrivalTime: null, departureTime: null });
  };

  handleArrivalTimeChange = time => {
    const { updateBookState, book } = this.props;
    const { departureTime } = book;

    const updatedBookState = {};

    if (!time) {
      return updateBookState({ arrivalTime: null, departureTime: null });
    }

    if (
      departureTime &&
      getMSFromTimeString(time) >= getMSFromTimeString(departureTime)
    ) {
      updatedBookState.departureTime = null;
    }

    updatedBookState.arrivalTime = time;

    updateBookState(updatedBookState);
  };

  handleDepartureTimeChange = time => {
    const { updateBookState } = this.props;
    updateBookState({ departureTime: time });
  };

  handleDayClick = (date, { available, disabled }) => {
    const {
      updateBookState,
      book: { additionalOrder }
    } = this.props;

    if (additionalOrder) {
      return;
    }

    if (available && !disabled) {
      updateBookState({
        date: moment(date.getTime()).format("MM/DD/YYYY"),
        bookedEquipment: [], // Reset booked equipment if date was changed
        paymentInfo: null // Rest payment info, cause inventory was removed
      });
    }
  };

  render() {
    const {
      changeStep,
      book: { date, departureTime, arrivalTime, additionalOrder }
    } = this.props;
    const {
      availableDays,
      timeOptions,
      disabledDays,
      initialMonth
    } = this.state;

    const filteredDepartureTimeOptions = timeOptions.filter(
      time =>
        arrivalTime &&
        getMSFromTimeString(time) > getMSFromTimeString(arrivalTime)
    );

    return (
      <>
        <section className="date-time">
          <h2 className="booking__title">Choose Date and Times</h2>

          <DayPicker
            className="calendar calendar--date-time"
            modifiers={{
              available: availableDays
            }}
            disabledDays={disabledDays}
            selectedDays={date && moment(date, "MM/DD/YYYY").toDate()}
            fromMonth={new Date()}
            onDayClick={this.handleDayClick}
            month={initialMonth}
            canChangeMonth={!additionalOrder}
          />

          <div className="date-time__content">
            <div className="date-time__timepickers">
              <div className="date-time__timepicker">
                <span>Arrival time</span>
                <select
                  disabled={!!additionalOrder || !date}
                  onChange={e => {
                    const val = e.target.value;
                    this.handleArrivalTimeChange(val);
                  }}
                  value={arrivalTime || ""}
                >
                  <option value="">- Select -</option>
                  {timeOptions.map(opt => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select>
              </div>

              <div className="date-time__timepicker">
                <span>Departure time</span>
                <select
                  disabled={!!additionalOrder || !date}
                  onChange={e => {
                    const val = e.target.value;
                    this.handleDepartureTimeChange(val);
                  }}
                  value={departureTime || ""}
                >
                  <option value="">- Select -</option>
                  {filteredDepartureTimeOptions.map(opt => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="date-time__additional-info">
              <p>
                We strongly recommend arriving as early as possible to ensure
                you find a parking spot. The Beach Butler is not affiliated with
                any parking lots/structures and cannot guarantee you a spot. The
                inability to find parking is not a condition in which we will
                provide a refund. However, we would be happy to help guide you
                towards other parking options if you contact us.
              </p>
              <p>
                It is ok if you decide to leave sooner or later than the
                selected departure time. Departure time will be used by Beach
                Butler to collect the equipment. Please keep in mind the latest
                we can collect equipment is 5:00pm.
              </p>
            </div>
          </div>
        </section>

        <div className="booking-controls">
          <div className="booking-controls__inner">
            <button
              className="booking-controls__back"
              onClick={() => changeStep(0)}
            >
              Back to Beaches
            </button>

            <button
              className="booking-controls__submit"
              disabled={date && arrivalTime && departureTime ? false : true}
              onClick={() => changeStep(2)}
            >
              Next
            </button>
          </div>
        </div>
      </>
    );
  }
}

DateTime.propTypes = {
  book: PropTypes.object.isRequired,
  updateBookState: PropTypes.func.isRequired,
  showInfo: PropTypes.func.isRequired
};

const mapStateToProps = ({ book }) => ({
  book
});

export default connect(mapStateToProps, { updateBookState, showInfo })(
  DateTime
);
