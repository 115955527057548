import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Portal from "../Portal/Portal";
import classes from "./Modal.module.scss";

const Modal = ({
  title,
  classNames = {},
  show,
  closeHandler,
  cancelButton,
  cancelHandler,
  children,
  okButton,
  okHandler,
  closeByEnterClick = false,
  isError
}) => {
  useEffect(() => {
    if (!closeByEnterClick) return;

    const fn = e => {
      if (e.keyCode === 13) {
        okHandler ? okHandler() : closeHandler();
      }

      if (e.keyCode === 27) {
        closeHandler();
      }
    };

    window.addEventListener("keyup", fn);

    return () => window.removeEventListener("keyup", fn);
  }, [closeHandler, okHandler, closeByEnterClick]);

  return show ? (
    <Portal>
      <div className={classes.overlay} onClick={closeHandler} />

      <div
        className={classnames(`${classes.modal} ${classNames.modal || ""}`, {
          [classes.error]: isError
        })}
      >
        <button
          className={classes.closeBtn}
          title="Close"
          onClick={closeHandler}
        >
          &times;
        </button>

        {title && <b className={classes.title}>{title}</b>}

        {children}

        <div className={classes.actions}>
          {cancelButton && (
            <button
              className={classes.cancelButton}
              onClick={() => (cancelHandler ? cancelHandler() : closeHandler())}
            >
              Cancel
            </button>
          )}
          {okButton && (
            <button
              className={classes.okButton}
              onClick={okHandler ? okHandler : closeHandler}
            >
              OK
            </button>
          )}
        </div>
      </div>
    </Portal>
  ) : null;
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired
};

export default Modal;
