import React, { Component, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import MaskedInput from "react-text-mask";

import { updateBookState } from "actions/bookActions";
import {
  validateEmail,
  validateCustomerInfo,
  validatePhone,
  validateZip
} from "validations";

const phoneMask = [
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

const yearsOptions = [];

for (
  let i = new Date().getFullYear() - 100;
  i <= new Date().getFullYear() - 10;
  i++
) {
  yearsOptions.push(i);
}

const InputGroup = ({
  format,
  error,
  label,
  type = "text",
  validate,
  setError,
  name,
  required,
  onChange,
  accept,
  component: Component,
  errorMessage,
  ...props
}) => {
  const localOnChange = useMemo(
    () => e => {
      const { value } = e.target;

      const formattedValue = (format && format(value)) || value;

      if (accept) {
        if (accept(formattedValue)) {
          onChange(name, formattedValue);
        }
      } else {
        onChange(name, formattedValue);
      }
    },
    [format, accept, onChange, name]
  );

  const localBlur = useMemo(
    () => e => {
      if (!validate) return;
      const isValid = validate(e.target.value);
      if (isValid) return;

      setError(name, errorMessage);
    },
    [validate, setError, name, errorMessage]
  );

  return (
    <div
      className={classnames("customer-info__fieldgroup", {
        invalid: !!error
      })}
    >
      <label className={required ? "required" : ""}>{label}</label>

      {Component ? (
        <Component
          {...props}
          type={type}
          onBlur={localBlur}
          onChange={localOnChange}
        />
      ) : (
        <input
          {...props}
          type={type}
          onBlur={localBlur}
          onChange={localOnChange}
        />
      )}

      {!!error && <span className="customer-info__field-error">{error}</span>}
    </div>
  );
};

class CustomerInfo extends Component {
  state = {
    errors: {}
  };

  // componentDidMount() {
  //   const { book } = this.props;

  //   if (book.customerInfo) {
  //     this.setState({ customerInfo: book.customerInfo });
  //   }
  // }

  updateInfo = (field, value) => {
    const { updateBookState, book } = this.props;

    updateBookState({ customerInfo: { ...book.customerInfo, [field]: value } });

    this.setState(state => ({
      errors: { ...state.errors, [field]: null }
    }));
  };

  setError = (field, error) => {
    this.setState(state => ({ errors: { ...state.errors, [field]: error } }));
  };

  onChangeKnowFrom = e => {
    const { updateBookState, book } = this.props;
    const val = e.target.value;

    const updatedCustomerInfo = { ...book.customerInfo };

    updatedCustomerInfo.knowFrom = val;

    if (val !== "Other") {
      updatedCustomerInfo.knowFromOther = "";
    }

    updateBookState({ customerInfo: updatedCustomerInfo });
  };

  render() {
    const {
      changeStep,
      updateBookState,
      book: { customerInfo, additionalOrder }
    } = this.props;
    const { errors } = this.state;

    const isAdditionalOrder = !!additionalOrder;

    const {
      knowFrom,
      knowFromOther,
      firstName,
      lastName,
      zipCode,
      birthYear,
      phone,
      email,
      agreement
    } = customerInfo;

    return (
      <section className="customer-info">
        <h2 className="booking__title">Customer Information</h2>

        <div className="customer-info__form">
          <div className="customer-info__fieldgroup">
            <label className="required">First Name</label>
            <input
              disabled={isAdditionalOrder}
              type="text"
              value={firstName || ""}
              onChange={e => {
                this.updateInfo("firstName", e.target.value);
              }}
            />
          </div>
          <div className="customer-info__fieldgroup">
            <label className="required">Last Name</label>
            <input
              disabled={isAdditionalOrder}
              type="text"
              value={lastName || ""}
              onChange={e => {
                this.updateInfo("lastName", e.target.value);
              }}
            />
          </div>

          <InputGroup
            required
            disabled={isAdditionalOrder}
            error={errors.zipCode}
            label="ZIP CODE"
            value={zipCode || ""}
            validate={validateZip}
            setError={this.setError}
            mask={zipMask}
            guide={false}
            keepCharPositions
            component={MaskedInput}
            name="zipCode"
            type="tel"
            onChange={this.updateInfo}
            accept={val => val.length < 6}
            errorMessage="Zip Code is not valid"
          />

          <div className="customer-info__fieldgroup">
            <label className="required">Year of Birth</label>
            <select
              disabled={isAdditionalOrder}
              value={birthYear || ""}
              onChange={e => {
                this.updateInfo("birthYear", e.target.value);
              }}
            >
              {yearsOptions.map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <InputGroup
            required
            disabled={isAdditionalOrder}
            error={errors.phone}
            label="Mobile Phone Number"
            value={phone || ""}
            validate={validatePhone}
            setError={this.setError}
            mask={phoneMask}
            guide={false}
            keepCharPositions
            component={MaskedInput}
            name="phone"
            type="tel"
            onChange={this.updateInfo}
            errorMessage="Phone number is not valid"
          />
          {/* <div className="customer-info__fieldgroup">
            <label>Mobile Phone Number</label>

            <IMaskInput
              radix="."
              mask="000-000-0000"
              value={phone}
              onAccept={(value, mask) => this.updateInfo('phone', value)}
            />
          </div> */}

          <InputGroup
            required
            disabled={isAdditionalOrder}
            error={errors.email}
            label="Email Address"
            value={email || ""}
            validate={validateEmail}
            setError={this.setError}
            name="email"
            type="email"
            onChange={this.updateInfo}
            errorMessage="Email is not valid"
          />

          <span className="customer-info__info">
            Mobile Phone Number and Email Address will only be used for
            communication leading up to and/or during your stay if needed.
          </span>

          <label className="required">How did you hear about us?</label>
          <div className="customer-info__radio-wrap">
            <label>
              <input
                disabled={isAdditionalOrder}
                name="knowFrom"
                type="radio"
                value="On the beach"
                checked={knowFrom === "On the beach"}
                onChange={this.onChangeKnowFrom}
              />
              On the beach
            </label>
            <label>
              <input
                disabled={isAdditionalOrder}
                name="knowFrom"
                type="radio"
                value="From a friend"
                checked={knowFrom === "From a friend"}
                onChange={this.onChangeKnowFrom}
              />
              From a friend
            </label>
            <label>
              <input
                disabled={isAdditionalOrder}
                name="knowFrom"
                type="radio"
                value="Facebook"
                checked={knowFrom === "Facebook"}
                onChange={this.onChangeKnowFrom}
              />
              Facebook
            </label>
            <label>
              <input
                disabled={isAdditionalOrder}
                name="knowFrom"
                type="radio"
                value="Instagram"
                checked={knowFrom === "Instagram"}
                onChange={this.onChangeKnowFrom}
              />
              Instagram
            </label>
            <label>
              <input
                disabled={isAdditionalOrder}
                name="knowFrom"
                type="radio"
                value="In my rental"
                checked={knowFrom === "In my rental"}
                onChange={this.onChangeKnowFrom}
              />
              In my rental
            </label>
            <label>
              <input
                disabled={isAdditionalOrder}
                name="knowFrom"
                type="radio"
                value="Other"
                checked={knowFrom === "Other"}
                onChange={this.onChangeKnowFrom}
              />
              Other:
            </label>
          </div>

          <input
            type="text"
            disabled={knowFrom !== "Other" || isAdditionalOrder}
            value={knowFromOther || ""}
            placeholder="If none of the above, please choose Other and specify."
            onChange={e => {
              this.updateInfo("knowFromOther", e.target.value);
            }}
          />

          <span className="customer-info__agreement">
            <input
              type="checkbox"
              disabled={isAdditionalOrder}
              checked={!!agreement}
              onChange={e => {
                this.updateInfo("agreement", e.target.checked);
              }}
            />
            <span>
              I agree to the{" "}
              <a
                href="/docs/Beach Butler Terms & Conditions.pdf"
                target="_blank"
              >
                Beach Butler Terms & Conditions
              </a>
            </span>
          </span>
        </div>

        <div className="booking-controls">
          <div className="booking-controls__inner">
            <button
              className="booking-controls__back"
              onClick={() => {
                changeStep(3);
              }}
            >
              Back to Equipment
            </button>

            <button
              className="booking-controls__submit"
              disabled={!validateCustomerInfo(customerInfo)}
              onClick={() => {
                updateBookState({
                  customerInfo
                });
                changeStep(5);
              }}
            >
              Next
            </button>
          </div>
        </div>
      </section>
    );
  }
}

CustomerInfo.ptopTypes = {
  book: PropTypes.object.isRequired,
  updateBookState: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  book: state.book
});

export default connect(mapStateToProps, { updateBookState })(CustomerInfo);
