import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ChooseBeach from "./steps/choose-beach";
import DateTime from "./steps/date-time";
import MapPin from "./steps/map-pin";
import Equipment from "./steps/equipment/equipment";
import CustomerInfo from "./steps/customer-info";
import Summary from "./steps/summary";
import Payment from "./steps/payment";
import Thanks from "./steps/thanks";
import { updateBookState } from "actions/bookActions";

const steps = [
  ChooseBeach,
  DateTime,
  MapPin,
  Equipment,
  CustomerInfo,
  Summary,
  Payment,
  Thanks
];

class BookNow extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.currentStep !== this.props.currentStep) {
      window.scrollTo(0, 0);
    }
  }

  changeStep = currentStep => {
    this.props.updateBookState({ currentStep });
  };

  render() {
    let StepPage = null;
    const { currentStep } = this.props;

    StepPage = steps[currentStep];

    if (!StepPage) {
      alert("No Step");
      return null;
    }

    return (
      <main className="booking">
        {StepPage && <StepPage changeStep={this.changeStep} />}
      </main>
    );
  }
}

BookNow.propTypes = {
  currentStep: PropTypes.number,
  updateBookState: PropTypes.func.isRequired
};

const mapStateToProps = ({ book }) => ({
  currentStep: book.currentStep
});

export default connect(mapStateToProps, { updateBookState })(BookNow);
