import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { closeModal } from "actions/modalActions";
import Modal from "../Modal/Modal";
import classes from "./Modals.module.scss";

const Dialogs = ({ modal, closeModal }) => {
  const { info, prompt, confirm } = modal;

  return (
    <>
      {info && (
        <Modal
          show={!!info}
          closeHandler={closeModal}
          isError={info.isError}
          okButton
          okHandler={() => {
            closeModal();
            info.cb && info.cb();
          }}
        >
          {info.html}
        </Modal>
      )}

      {prompt && <PromptModal data={prompt} closeModal={closeModal} />}
      {confirm && <ConfirmModal data={confirm} closeModal={closeModal} />}
    </>
  );
};

class ConfirmModal extends Component {
  clickHandler = isConfirmed => {
    const { closeModal, data } = this.props;
    const { cb } = data;

    cb(isConfirmed);
    closeModal();
  };

  render() {
    const {
      data: { html = "" }
    } = this.props;

    return (
      <Modal
        show={!!prompt}
        closeHandler={this.clickHandler.bind(null, false)}
        okButton
        okHandler={this.clickHandler.bind(null, true)}
        cancelButton
        cancelHandler={this.clickHandler.bind(null, false)}
      >
        <>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </>
      </Modal>
    );
  }
}

class PromptModal extends Component {
  state = { value: "" };

  componentDidMount() {
    const {
      data: { initialValue }
    } = this.props;

    if (initialValue) {
      this.setState({ value: initialValue });
    }

    this.input.current.focus();
  }

  okHandler = e => {
    const { closeModal, data } = this.props;
    const { cb } = data;
    const { value } = this.state;

    e && e.preventDefault && e.preventDefault();
    cb(value);
    closeModal();
  };

  closeHandler = e => {
    const { closeModal, data } = this.props;
    const { cb } = data;

    e && e.preventDefault && e.preventDefault();
    cb();
    closeModal();
  };

  changeValueHandler = e => {
    this.setState({ value: e.target.value });
  };

  input = React.createRef();

  render() {
    const {
      data: { inputProps = {}, html = "", underInputHtml = "" }
    } = this.props;

    const { value } = this.state;

    return (
      <Modal
        show={!!prompt}
        closeHandler={this.closeHandler}
        okButton
        okHandler={this.okHandler}
      >
        <>
          <div dangerouslySetInnerHTML={{ __html: html }} />

          <input
            ref={this.input}
            className={classes.promptField}
            type="text"
            {...(inputProps || {})}
            value={value}
            onChange={this.changeValueHandler}
          />

          <div dangerouslySetInnerHTML={{ __html: underInputHtml }} />
        </>
      </Modal>
    );
  }
}

Dialogs.propTypes = {
  modal: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  modal: state.modal
});

export default connect(mapStateToProps, { closeModal })(Dialogs);
