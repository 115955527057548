import React from "react";
import { Link } from "react-router-dom";

export default class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
    };
  }

  changeSection(number) {
    console.log(number);
    if (this.state.active === number) this.setState({ active: 0 });
    else this.setState({ active: number });
  }

  render() {
    const { active } = this.state;

    return (
      <main id="faq">
        <h1>Frequently Asked Questions</h1>
        <div className="faq-wrapper">
          <div className="row title">ARRIVAL & DEPARTURE</div>
          <div
            className={active === 1 ? "row active" : "row"}
            onClick={() => {
              this.changeSection(1);
            }}
          >
            <label>Do I need to check in when I arrive? </label>
            <span>
              No, there is no need to check in with the Beach Butler when you
              arrive at the beach. Simply make your way to the beach without
              lugging all the heavy equipment! Once you are settled your Beach
              Butler will check in on you to ensure you have everything you
              need. After that initial check in, your Beach Butler will only
              approach you if you raise your flag.
            </span>
          </div>
          <div
            className={active === 2 ? "row active" : "row"}
            onClick={() => {
              this.changeSection(2);
            }}
          >
            <label>How do I find my spot?</label>
            <span>
              When you arrive on the beach, look for a Beach Butler flag with
              your Flag ID number on it. We will do our best to setup your spot
              as close to the location you pinned on the map as possible.
            </span>
          </div>
          <div
            className={active === 3 ? "row active" : "row"}
            onClick={() => {
              this.changeSection(3);
            }}
          >
            <label>What do I do if I want to move my spot?</label>
            <span>
              You are welcome to move your location within a reasonable
              distance, but just be sure to let us know by emailing{" "}
              <Link to="/contact-us">info@mybeachbutler.com</Link> so we can be
              sure to find your equipment for pickup at the end of the day.
              Also, we recommend that you allow a Butler to help you move your
              umbrella if you have rented one. Simply raise your flag and we’ll
              stop by as soon as possible.
            </span>
          </div>
          <div
            className={active === 4 ? "row active" : "row"}
            onClick={() => {
              this.changeSection(4);
            }}
          >
            <label>Can you bring us any food or drink?</label>
            <span>
              Unfortunately, we are not permitted to serve any food or drink at
              this time.
            </span>
          </div>
          <div
            className={active === 5 ? "row active" : "row"}
            onClick={() => {
              this.changeSection(5);
            }}
          >
            <label>
              Do I have to stay until the Beach Butler picks up my equipment?{" "}
            </label>
            <span>
              No. You are welcome to leave as soon as you’re ready. A Butler
              will be around to clean up your equipment at the time you indicate
              on your reservation. If you leave earlier or decide to stay later,
              it is not a problem. We do, however, collect all equipment by
              5:00pm.
            </span>
          </div>
          <div className="row title">RESERVATIONS</div>
          <div
            className={active === 6 ? "row active" : "row"}
            onClick={() => {
              this.changeSection(6);
            }}
          >
            <label>What happens if my order is not correct? </label>
            <span>
              A Beach Butler will check in with you soon after you arrive to
              make sure your order is correct. If you need service more
              urgently, you have two options: raise your flag and your Butler
              will approach and/or please email us at{" "}
              <Link to="/contact-us">info@mybeachbutler.com</Link>.
            </span>
          </div>
          <div
            className={active === 7 ? "row active" : "row"}
            onClick={() => {
              this.changeSection(7);
            }}
          >
            <label>How do I order more equipment after I arrived?</label>
            <span>
              The Beach Butler is here to make your beach visit as enjoyable as
              possible. If you would like to add something to your existing
              reservation (cooler, umbrella, more towels or chairs, etc.), you
              can use the link provided in your confirmation email. You will
              need to follow that link and enter your Order ID (not your Flag
              ID) and then you can add equipment.
            </span>
          </div>
          <div
            className={active === 8 ? "row active" : "row"}
            onClick={() => {
              this.changeSection(8);
            }}
          >
            <label>Can I make a reservation for more than one day?</label>
            <span>
              We determined we will not be doing multiple day reservations at
              this time. The workflow was clunky and we decided not to put
              time/resources into making it better (yet).
            </span>
          </div>
          <div
            className={active === 9 ? "row active" : "row"}
            onClick={() => {
              this.changeSection(9);
            }}
          >
            <label>Are packages or memberships available?</label>
            <span>
              Currently, the Beach Butler equipment is offered a la carte.
              However, we do plan to offer packages in the future. We also are
              considering a membership plan for people who visit Salisbury Beach
              often. If you are interested, we would love to hear from you.
              Please email us at{" "}
              <Link to="/contact-us">info@mybeachbutler.com</Link>.
            </span>
          </div>
          <div
            className={active === 10 ? "row active" : "row"}
            onClick={() => {
              this.changeSection(10);
            }}
          >
            <label>How do I change my reservation?</label>
            <span>
              If you need to change your dates or remove items from your
              reservation, please email us at{" "}
              <Link to="/contact-us">info@mybeachbutler.com</Link>. Please
              provide changes before 6:00 pm the day before your reservation.
            </span>
          </div>
          <div
            className={active === 11 ? "row active" : "row"}
            onClick={() => {
              this.changeSection(11);
            }}
          >
            <label>How do I cancel my reservation?</label>
            <span>
              We understand plans (and weather!) can change. If you need to
              cancel, please email us at{" "}
              <Link to="/contact-us">info@mybeachbutler.com</Link>.  Please
              contact us by 6:00 pm the day before your reservation and we can
              process a refund. Unfortunately, we cannot accept cancellations
              after 6:00 pm the day before, or on the day of the reservation.
            </span>
          </div>
          <div className="row title">ISSUES</div>
          <div
            className={active === 12 ? "row active" : "row"}
            onClick={() => {
              this.changeSection(12);
            }}
          >
            <label>
              What happens if I can’t find parking and I already made a
              reservation?
            </label>
            <span>
              We strongly recommend arriving as early as possible to ensure you
              can find parking. The beach parking lots fill up very quickly,
              especially on nice weather days and holidays. Unfortunately, the
              inability to find parking is not a condition in which we can
              provide a refund. Uber is always another option and can make going
              to the beach even easier!
            </span>
          </div>
          <div
            className={active === 13 ? "row active" : "row"}
            onClick={() => {
              this.changeSection(13);
            }}
          >
            <label>
              What happens if there is bad weather and I decide to leave?
            </label>
            <span>
              Unfortunately, we cannot provide refunds for inclement weather. We
              recommend checking the weather in advance of your reservation and
              if bad weather is expected, we will accept cancellations the day
              prior, up to 6:00 pm. Please email us at{" "}
              <Link to="/contact-us">info@mybeachbutler.com</Link>.
            </span>
          </div>
          <div
            className={active === 14 ? "row active" : "row"}
            onClick={() => {
              this.changeSection(14);
            }}
          >
            <label>What happens if equipment gets broken or lost?</label>
            <span>
              Please notify the Beach Butler at{" "}
              <Link to="/contact-us">info@mybeachbutler.com</Link> if something
              breaks or gets lost. You are liable for the cost of the equipment
              if at fault. We review inventory at the end of every day, and will
              notify you if there is a discrepancy or issue.
            </span>
          </div>
        </div>
      </main>
    );
  }
}
