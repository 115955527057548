import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import { showInfo } from "actions/modalActions";
import moment from "moment";

import {
  setBookStateForAdditionalOrder,
  resetBookState,
} from "actions/bookActions";

class AdditionalOrder extends React.Component {
  state = {
    orderId: "",
  };

  componentDidMount() {
    const { resetBookState } = this.props;
    resetBookState();
    this.input.current.focus();
  }

  submit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { setBookStateForAdditionalOrder, showInfo, history } = this.props;
    const { orderId } = this.state;

    const url = `/api/reservations/get-reservation-by-number/${orderId}`;

    axios
      .get(url)
      .then((response) => {
        const { data: reservation } = response;

        if (reservation.isCancelled) {
          return showInfo({
            html: (
              <div style={{ textAlign: "center" }}>
                Your reservation was cancelled
              </div>
            ),
          });
        }

        // Check that current date is less that reservation date 2pm
        const reservationDate = moment(reservation.date, "MM/DD/YYYY")
          .hours(14)
          .minutes(0)
          .valueOf();

        const currentDate = Date.now();

        if (currentDate < reservationDate) {
          setBookStateForAdditionalOrder(reservation);

          history.push(`/book`);
        } else {
          showInfo({
            html:
              "Sorry, you can't book any additional equipment to this reservation.",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        showInfo({
          isError: true,
          html: (
            <p style={{ textAlign: "center" }}>
              {(err.response &&
                err.response.data &&
                err.response.data.message) ||
                "Something goes wrong. Please try again"}
            </p>
          ),
        });
      });
  };

  input = React.createRef();

  inputHandler = (e) => {
    const { value } = e.target;

    this.setState({ orderId: value });
  };

  render() {
    const { orderId } = this.state;

    return (
      <main className="additional-order">
        <form onSubmit={this.submit}>
          <h2>Additional order</h2>
          <label>
            Please enter your order ID to add more equipment to your
            reservation.
          </label>
          <input
            ref={this.input}
            onChange={this.inputHandler}
            value={orderId}
            className="number"
            type="text"
            required
          />
          <input type="submit" value="Continue" />
        </form>
      </main>
    );
  }
}

AdditionalOrder.propTypes = {
  setBookStateForAdditionalOrder: PropTypes.func.isRequired,
  resetBookState: PropTypes.func.isRequired,
  showInfo: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(null, {
  setBookStateForAdditionalOrder,
  showInfo,
  resetBookState,
})(AdditionalOrder);
