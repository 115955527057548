export const validateZip = zip => /(^\d{5})$/.test(zip);

export const validatePhone = phone =>
  !!phone.match(/^(\+?\d{10}|\+?\d{3}-\d{3}-\d{4})$/);

export const validateEmail = email =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
    email
  );

export const validateCustomerInfo = info => {
  return !!(
    info.firstName &&
    info.lastName &&
    info.zipCode &&
    validateZip(info.zipCode) &&
    info.birthYear &&
    info.phone &&
    validatePhone(info.phone) &&
    info.email &&
    validateEmail(info.email) &&
    info.knowFrom &&
    (info.knowFrom !== 'Other' || info.knowFromOther) &&
    info.agreement
  );
};

export { default as getValidateFunc } from './get-validate-func';
