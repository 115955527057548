import axios from "axios";

import beaches from "./beachesAPI";
import inventory from "./inventoryAPI";
import testimonials from "./testimonialsAPI";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default {
  beaches,
  inventory,
  testimonials,
};
