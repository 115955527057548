import {
  UPDATE_BOOK_STATE,
  SELECT_BEACH,
  RESET_BOOK_STATE,
  SET_BOOK_STATE_FOR_ADDITIONAL_ORDER
} from "actions/types";

// const dummyData = {
  // arrivalTime: '10:00 AM',
  // departureTime: '12:00 PM',
  // date: '07/20/2020',
  // location: '(42.82593604569192, -70.81327888894015)',
  // bookedEquipment: [
  //   { _id: '5d2f13d81c480000245c98c1', quantity: 3 },
  //   { _id: '5d2f13941c480000245c98bd', quantity: 4 },
  //   { _id: '5d2f136a1c480000245c98bc', quantity: 4 }
  // ],
  // customerInfo: {
  //   birthYear: 1990,
  //   firstName: 'Test First Name',
  //   lastName: 'Test Last Name',
  //   zipCode: '12345',
  //   phone: '123-456-7890',
  //   email: 'dbondar@envionsoftware.com',
  //   knowFrom: 'Facebook',
  //   agreement: true
  // }
// };

const initialState = {
  beach: null,
  bookedEquipment: [],
  arrivalTime: null,
  departureTime: null,
  paymentInfo: null,
  location: null,
  date: null,
  customerInfo: {
    birthYear: 1980,
  },
  currentStep: 0
};

const filter = "book";

export default (state = initialState, action) => {
  if (filter !== action.filter) return state;

  switch (action.type) {
    case UPDATE_BOOK_STATE:
      const newState = {
        ...state,
        ...action.payload
      };

      localStorage.setItem("bookState", JSON.stringify(newState));
      localStorage.setItem("lastUpdate", new Date());
      return newState;
    case SET_BOOK_STATE_FOR_ADDITIONAL_ORDER:
      const reservation = action.payload;

      const additionalOrder = reservation.reservationNumber;

      // Remove extra data
      delete reservation._id;
      delete reservation.bookedEquipment;
      delete reservation.paymentInfo;
      delete reservation.createDate;
      delete reservation.reservationNumber;

      return {
        additionalOrder,
        currentStep: 3,
        bookedEquipment: [],
        ...action.payload
      };
    case SELECT_BEACH: {
      // Reset booked equipment, location, date and times if beach was changed
      return {
        ...state,
        beach: action.payload,
        bookedEquipment: [],
        paymentInfo: null,
        location: null,
        arrivalTime: null,
        departureTime: null,
        date: null
      };
    }
    case RESET_BOOK_STATE:
      localStorage.removeItem("bookState");
      localStorage.removeItem("lastUpdate");
      return initialState;
    default:
      return state;
  }
};
