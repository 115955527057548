import React, { Component } from "react";

import API from "api";

class Testimonials extends Component {
  state = {
    testimonials: null,
  };

  componentDidMount() {
    API.testimonials.fetchList().then((list) => {
      this.setState({ testimonials: list });
    });
  }

  render() {
    const { testimonials } = this.state;

    return (
      <main id="testimonials">
        <h1>Reviews</h1>

        <div className="testimonials-wrapper">
          {testimonials &&
            testimonials.map((testimonial) => {
              return (
                <div key={testimonial._id} className="testimonial-card">
                  {testimonial.text}
                </div>
              );
            })}
        </div>
      </main>
    );
  }
}

export default Testimonials;
