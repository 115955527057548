import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { selectBeach, resetBookState } from "actions/bookActions";

import API from "api";
import { showInfo, showConfirm } from "actions/modalActions";

export class ChooseBeach extends Component {
  state = { beaches: null };

  componentDidMount() {
    const { showInfo, history, selectedBeach } = this.props;

    API.beaches.fetchList().then(beaches => {
      const formattedBeaches = beaches
        .filter(b => !b.hidden)
        .sort((a, b) => a.order - b.order);

      if (!formattedBeaches.length) {
        return showInfo({
          html: "Sorry, there are no available beaches at this moment",
          cb: () => history.push("/")
        });
      }

      // if (formattedBeaches.length === 1) {
      //   return this.setState({ selected: selectedBeach }, this.goToNextStep);
      // }

      this.setState({ beaches: formattedBeaches, selected: selectedBeach });
    });
  }

  onBeachSelectHandler = beach => {
    const { isAdditionalOrder, showInfo } = this.props;
    const { selected } = this.state;

    if (selected && selected._id === beach._id) {
      return;
    }

    if (isAdditionalOrder) {
      return showInfo({
        html: "Sorry, you can't change the beach, cause it's additional order"
      });
    }

    this.setState({ selected: beach });
  };

  goToNextStep = () => {
    const { selectBeach, changeStep, selectedBeach } = this.props;
    const { selected } = this.state;

    if (!selectedBeach || selectedBeach._id !== selected._id) {
      selectBeach({ name: selected.name, _id: selected._id });
    }

    changeStep(1);
  };

  cancelOrder = () => {
    const { history, resetBookState, showConfirm } = this.props;

    showConfirm({
      html: "Are you sure you want to cancel booking?"
    }).then(isConfirmed => {
      if (!isConfirmed) return;

      resetBookState();
      history.push("/");
    });
  };

  render() {
    const { beaches, selected } = this.state;
    if (!beaches) return null;

    return (
      <>
        <section className="choose-beach">
          <h2 className="booking__title">Choose Beach</h2>

          <ul
            className={classnames("choose-beach__list", {
              "choose-beach__list--1": beaches.length === 1
            })}
          >
            {beaches.map(beach => {
              return (
                <li
                  onClick={this.onBeachSelectHandler.bind(null, beach)}
                  className={classnames("choose-beach__item", {
                    selected: selected && selected._id === beach._id
                  })}
                  key={beach._id}
                >
                  <div className="choose-beach__stroke choose-beach__stroke--1"></div>
                  <div className="choose-beach__stroke choose-beach__stroke--2"></div>
                  <div className="choose-beach__stroke choose-beach__stroke--3"></div>
                  <div className="choose-beach__stroke choose-beach__stroke--4"></div>

                  <div
                    className="choose-beach__img"
                    style={{
                      backgroundImage: `url("${process.env.REACT_APP_API_URL}/beach-images/${beach.image}")`
                    }}
                  ></div>
                  <h3 className="choose-beach__title">{beach.name}</h3>
                </li>
              );
            })}
          </ul>
        </section>

        <div className="booking-controls">
          <div className="booking-controls__inner">
            <button
              onClick={this.cancelOrder}
              type="button"
              className="booking-controls__cancel"
            >
              Cancel
            </button>

            <button
              className="booking-controls__submit"
              disabled={!selected}
              onClick={this.goToNextStep}
            >
              Next
            </button>
          </div>
        </div>
      </>
    );
  }
}

ChooseBeach.propTypes = {
  isAdditionalOrder: PropTypes.bool,
  history: PropTypes.object.isRequired,
  selectedBeach: PropTypes.object,
  showInfo: PropTypes.func.isRequired,
  showConfirm: PropTypes.func.isRequired,
  selectBeach: PropTypes.func.isRequired,
  resetBookState: PropTypes.func.isRequired
};

const mapStateToProps = ({ book }) => ({
  selectedBeach: book.beach,
  isAdditionalOrder: !!book.additionalOrder
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    selectBeach,
    showInfo,
    showConfirm,
    resetBookState
  })
)(ChooseBeach);
