import React from "react";
import { Provider } from "react-redux";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";

import store from "./store";
import { Modals, Navigation, Footer } from "components";
import ScrollToTop from "components/ScrollToTop";

import {
  FAQ,
  Home,
  HowTo,
  BookNow,
  Pricing,
  ContactUs,
  PrivacyPolicy,
  AdditionalOrder,
  Testimonials,
} from "pages";
import { updateBookState, resetBookState } from "actions/bookActions";

import "normalize.css";
import "react-day-picker/lib/style.css";
import "./scss/styles.scss";

const localBookState = localStorage.getItem("bookState");

if (localBookState) {
  const booksState = JSON.parse(localBookState);

  if (booksState._id) {
    // Completed Order
    store.dispatch(resetBookState());
  } else {
    let lastUpdate = localStorage.getItem("lastUpdate");
    lastUpdate = lastUpdate && new Date(lastUpdate);

    console.log(lastUpdate);

    // If last update was less than 15 min ago, then restore data
    if (lastUpdate && lastUpdate.valueOf() + 15 * 60 * 1000 > new Date()) {
      store.dispatch(updateBookState(booksState));
    }
  }
}

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <div className="App">
          <Modals />

          <div className="website-wrapper">
            <Navigation />

            <Switch>
              <Route exact path="/book" component={BookNow} />

              <Route>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route
                    exact
                    path="/additional-order"
                    component={AdditionalOrder}
                  />
                  <Route exact path="/contact-us" component={ContactUs} />
                  <Route
                    exact
                    path="/privacy-policy"
                    component={PrivacyPolicy}
                  />
                  <Route exact path="/pricing" component={Pricing} />
                  <Route exact path="/reviews" component={Testimonials} />
                  <Route exact path="/faq" component={FAQ} />
                  <Route exact path="/howto" component={HowTo} />

                  <Route>
                    <Redirect to="/" />
                  </Route>
                </Switch>

                <Footer />
              </Route>
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
