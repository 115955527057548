import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";

import { showInfo } from "actions/modalActions";
import { Spinner } from "components";

class ContactUs extends Component {
  state = {
    email: "",
    message: "",
    subject: "",
    sendingEmail: false,
  };

  resetFields = () => {
    this.setState({ email: "", message: "", subject: "" });
  };

  submitHandler = (e) => {
    e && e.preventDefault();

    const { showInfo } = this.props;

    this.setState({ sendingEmail: true });

    axios.post("/api/contact", this.state).then((response) => {
      let message;

      if (response.data.success) {
        this.resetFields();

        message =
          "Thank you for reaching out to us. A Beach Butler will get back to you soon!";
      } else {
        message =
          "There was an issue while sending email, please check all fields and try again";
      }

      this.setState({ sendingEmail: false });

      showInfo({
        html: <div style={{ textAlign: "center" }}>{message}</div>,
      });
    });
  };

  updateValue = (field, val) => {
    this.setState({ [field]: val });
  };

  render() {
    const { email, subject, message, sendingEmail } = this.state;

    return (
      <>
        <Spinner loading={sendingEmail} withOverlay />

        <main>
          <form className="contact-us-form" onSubmit={this.submitHandler}>
            <h2>Contact Us</h2>
            <span className="phone-span">
              Phone: <a href="tel:1-800-712-8708">1-800-712-8708</a>
            </span>
            <span className="email-span">
              Email: <label>info@mybeachbutler.com</label>
            </span>
            <label>Your Email</label>
            <input
              value={email}
              onChange={(e) => this.updateValue("email", e.target.value)}
              className="email"
              type="email"
              required
            />
            <label>Subject</label>
            <input
              value={subject}
              onChange={(e) => this.updateValue("subject", e.target.value)}
              className="subject"
              required
            />
            <label>Your message</label>
            <textarea
              value={message}
              onChange={(e) => this.updateValue("message", e.target.value)}
              className="message"
              required
            ></textarea>
            <input type="submit" value="Submit" />
          </form>
        </main>
      </>
    );
  }
}

ContactUs.propTypes = {
  showInfo: PropTypes.func.isRequired,
};

export default connect(null, { showInfo })(ContactUs);
