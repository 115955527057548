import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    delete localStorage.additionalOrderId;
  }, []);

  return (
    <main>
      <section className="heading">
        <div className="heading__text-block">
          <h2>The Beach, Made Easy</h2>

          <p>
            Enjoy the beach to the fullest while the Beach Butler does the heavy
            lifting.
          </p>

          <Link to="/book" className="submit">
            Book Now
          </Link>
        </div>
      </section>

      <section className="benefits">
        <div className="benefits__item">
          <div className="tile">
            <div>
              <h2>Maximize your beach experience with us</h2>
              <span>
                Tired of lugging endless supplies to the beach? Let the Beach
                Butler do the heavy lifting for you. We’re here to make your
                beach experience as enjoyable as possible by renting chairs,
                umbrellas, towels, coolers, games and more. Simply let us know
                where you want to sit, what equipment you want to have there and
                we’ll take care of the rest.
              </span>
            </div>
          </div>
          <div className="img-tile">
            <img src="img/beach-experience-img.jpg" alt="" />
          </div>
        </div>

        <div className="benefits__item">
          <div className="tile">
            <div>
              <h2>STEP 1</h2>
              <h3>Make a reservation</h3>
              <span>
                Let us know what day and time you plan to arrive, approximately
                where you want to sit on the beach and what equipment you’d like
                to have setup.{" "}
              </span>
            </div>
          </div>
          <div className="img-tile">
            <img src="img/home-step-1.jpg" alt="" />
          </div>
        </div>

        <div className="benefits__item">
          <div className="tile">
            <div>
              <h2>STEP 2</h2>
              <h3>Arrive and enjoy</h3>
              <span>
                Prior to your arrival, a Beach Butler will setup your area with
                your rental equipment. We’ll mark it with an easy to find flag.
                A Beach Butler will check on you to make sure you have
                everything you need.
              </span>
            </div>
          </div>
          <div className="img-tile">
            <img src="img/home-step-2.jpg" alt="" />
          </div>
        </div>

        <div className="benefits__item">
          <div className="tile">
            <div>
              <h2>STEP 3</h2>
              <h3>Depart worry-free</h3>
              <span>
                When you’re ready to leave, just pack yourselves up and leave
                the rental equipment. A Beach Butler will swing by to clean it
                all up.
              </span>
            </div>
          </div>
          <div className="img-tile">
            <img src="img/step-03@2x.png" alt="" />
          </div>
        </div>
      </section>

      <section id="ending">
        <h2>The beach shouldn’t be stressful, it should be fun! </h2>
      </section>
    </main>
  );
};

export default Home;
