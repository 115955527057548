import React from "react";
import { Link } from "react-router-dom";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { debounce } from "lodash";

const menuLinks = [
  { title: "Pricing", to: "/pricing" },
  { title: "Book Now", to: "/book" },
  { title: "Reviews", to: "/reviews" },
  { title: "Contact Us", to: "/contact-us" },
  { title: "FAQ", to: "/faq" },
  { title: "How To", to: "/howto" },
];

class Navigation extends React.Component {
  state = { showMenu: false };

  targetRef = React.createRef();
  targetElement = null;

  componentDidMount() {
    this.targetElement = this.targetRef.current;
    window.addEventListener("resize", this.debouncedCheckMenu);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
    window.removeEventListener("resize", this.debouncedCheckMenu);
  }

  checkMenu = () => {
    const { showMenu } = this.state;
    if (window.innerWidth >= 768 && showMenu) {
      this.closeMenu();
    }
  };

  debouncedCheckMenu = debounce(this.checkMenu, 500);

  closeMenu = () => {
    enableBodyScroll(this.targetElement);
    this.setState({ showMenu: false });
  };

  openMenu = () => {
    disableBodyScroll(this.targetElement);
    this.setState({ showMenu: true });
  };

  render() {
    const { showMenu } = this.state;

    return (
      <nav className={"nav" + (showMenu ? " active" : "")}>
        <div className="nav__top">
          <Link to="" onClick={this.closeMenu}>
            <div className="logo"></div>
          </Link>

          <button
            type="button"
            className={(showMenu ? "active " : "") + "menuButton"}
            onClick={showMenu ? this.closeMenu : this.openMenu}
          >
            <span></span>
          </button>
        </div>

        <ul className="nav__list" ref={this.targetRef}>
          {menuLinks.map((l) => (
            <li key={l.to}>
              <Link to={l.to} onClick={this.closeMenu}>
                {l.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    );
  }
}

export default Navigation;
