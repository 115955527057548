import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Modal } from "components";
import classes from "./additional-equipment.module.scss";

const AdditionalEquipment = ({
  item,
  booked,
  changeQuantity,
  closeHandler,
  okHandler
}) => {
  const current = _.find(booked, { _id: item._id });
  const currentQuantity = (current && current.quantity) || 0;

  return (
    <Modal
      show={true}
      closeHandler={closeHandler}
      okHandler={okHandler}
      okButton
    >
      <div className={classes.wrapper}>
        <b className={classes.lead}>Do you want to add {item.name}?</b>

        <img
          className={classes.img}
          src={`${process.env.REACT_APP_API_URL}/equipment-images/${item.image}`}
          alt=""
        />

        <div className={classes.text}>
          <strong>{item.name}</strong>
          <span>${item.price} per pack</span>

          <div className={classes.quantity}>
            <button onClick={() => changeQuantity(item._id, -1)}>-</button>

            <span>{currentQuantity}</span>

            <button onClick={() => changeQuantity(item._id, 1)}>+</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AdditionalEquipment.propTypes = {
  item: PropTypes.object.isRequired,
  booked: PropTypes.array.isRequired,
  changeQuantity: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired
};

export default AdditionalEquipment;
