import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import API from "api";
import { updateBookState } from "actions/bookActions";

class Summary extends Component {
  state = {
    amount: 0,
    tax: 0,
    totalAmount: 0,
  };

  componentDidMount() {
    Promise.all([
      API.inventory.fetchList(),
      API.beaches.getById(this.props.book.beach._id),
    ]).then((results) => {
      const [list, beach] = results;
      this.setState(
        { inventory: list, beachTax: beach.tax, beachPolygons: beach.polygons },
        this.updatePrice
      );
      this.initMap();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { book } = this.props;
    const { inventory } = this.state;

    if (
      prevProps.book.bookedEquipment !== book.bookedEquipment ||
      prevState.inventory !== inventory
    ) {
      this.updatePrice();
    }
  }

  updatePrice = () => {
    const {
      book: { bookedEquipment },
    } = this.props;
    const { inventory, beachTax } = this.state;

    const amount = _.reduce(
      bookedEquipment,
      (accum, booked) => {
        const item = _.find(inventory, { _id: booked._id });
        return accum + (item.price || 0) * booked.quantity;
      },
      0
    );

    const tax = parseFloat(((amount * beachTax) / 100).toFixed(2));
    const totalAmount = parseFloat((amount + tax).toFixed(2));

    this.setState({ amount, tax, totalAmount });
  };

  initMap = () => {
    const { book } = this.props;
    const { google } = window;

    const [lat, lng] = book.location.replace(/[\s()]/g, "").split(",");

    let lastCords = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));

    const map = new google.maps.Map(document.getElementById("map"), {
      center: lastCords,
      zoom: 16,
      mapTypeId: "satellite",
      draggable: false,
      disableDefaultUI: true,
      mapTypeControlOptions: {
        mapTypeIds: [],
      },
    });

    this.state.beachPolygons.forEach((polygon, pi) => {
      let coordinatesObject = polygon.map((e, i) => {
        return { lat: e[0], lng: e[1] };
      });

      const poly = new google.maps.Polygon({
        paths: coordinatesObject,
        strokeColor: "#FF007F",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF007F",
        fillOpacity: 0.35,
      });

      poly.setMap(map);
    });

    new google.maps.Marker({
      position: lastCords,
      map: map,
      icon: "img/pin.png",
    });

    new google.maps.Circle({
      strokeColor: "#ffffff",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#ffffff",
      fillOpacity: 0.7,
      map: map,
      center: lastCords,
      radius: 7,
    });
  };

  render() {
    const { book, updateBookState } = this.props;
    const { totalAmount, amount, inventory } = this.state;

    const { customerInfo, additionalOrder } = book;

    if (!inventory) return null;

    return (
      <>
        <section className="summary">
          <h2 className="booking__title">Summary</h2>

          {this.renderEquipmentTable()}

          <div className="summary__edit-equip">
            <button
              className="edit"
              onClick={() => {
                this.props.changeStep(3);
              }}
            >
              <img src="/img/pencil-edit-button@2x.png" alt="" />
              Edit Equipment
            </button>
          </div>

          <div className="summary__cards">
            <div className="summary__card">
              <h3>
                <img src="/img/user.svg" alt="" />
                Customer Info
              </h3>
              <div className="summary__card-inner">
                <label>Name:</label>
                <b>
                  {customerInfo.firstName} {customerInfo.lastName}
                </b>
                <label>Zip Code:</label>
                <b>{customerInfo.zipCode}</b>
                <label>Year of Birth:</label>
                <b>{customerInfo.birthYear}</b>
                <label>Mobile Phone Number:</label>
                <b>{customerInfo.phone}</b>
                <label>Email Address:</label>
                <b className="summary__card-email">{customerInfo.email}</b>
              </div>
              {!additionalOrder && (
                <button
                  className="edit"
                  onClick={() => {
                    this.props.changeStep(4);
                  }}
                >
                  <img src="/img/pencil-edit-button@2x.png" alt="" />
                  edit
                </button>
              )}
            </div>
            <div className="summary__card">
              <h3>
                <img src="/img/clock-circular-outline.svg" alt="" />
                Date
              </h3>

              <div className="summary__card-inner">
                <label>Date:</label>
                <b>{book.date}</b>
                <label>Arrival Time:</label>
                <b>{book.arrivalTime}</b>
                <label>Departure Time:</label>
                <b>{book.departureTime}</b>
              </div>

              {!additionalOrder && (
                <button
                  className="edit"
                  onClick={() => {
                    this.props.changeStep(1);
                  }}
                >
                  <img src="/img/pencil-edit-button@2x.png" alt="" />
                  edit
                </button>
              )}
            </div>
            <div className="summary__card">
              <h3>
                <img src="/img/placeholder.svg" alt="" />
                Location
              </h3>

              <div className="summary__card-inner">
                <label>Beach:</label>
                <b>{book.beach.name}</b>
              </div>

              <div id="map" />

              {!additionalOrder && (
                <button
                  className="edit"
                  onClick={() => {
                    this.props.changeStep(2);
                  }}
                >
                  <img src="/img/pencil-edit-button@2x.png" alt="" />
                  edit
                </button>
              )}
            </div>
          </div>
        </section>
        <div className="booking-controls">
          <div className="booking-controls__inner">
            <button
              className="booking-controls__back"
              onClick={() => {
                this.props.changeStep(4);
              }}
            >
              Back to Customer Information
            </button>
            <button
              className="booking-controls__submit"
              disabled={!totalAmount}
              onClick={() => {
                updateBookState({ paymentInfo: { amount } });
                this.props.changeStep(6); // payment
              }}
            >
              Next
            </button>
          </div>
        </div>
      </>
    );
  }

  renderEquipmentTable = () => {
    const {
      book: { bookedEquipment },
      updateBookState,
    } = this.props;
    const { inventory, amount, tax, totalAmount } = this.state;

    if (!inventory) return;

    return (
      <table className="summary__table">
        <tbody>
          <tr>
            <th>Equipment</th>
            <th>Quantity</th>
            <th>Price</th>
            <th></th>
          </tr>

          {bookedEquipment.map((equip) => {
            const inventoryItem = _.find(inventory, { _id: equip._id });
            const price = (inventoryItem.price * equip.quantity).toFixed(2);

            return (
              <tr key={equip._id}>
                <td className="summary__table-col-name">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/equipment-images/${inventoryItem.image}`}
                    alt=""
                  />
                  {inventoryItem.name}
                </td>

                <td className="summary__table-col-quantity">
                  {equip.quantity}
                </td>

                <td className="summary__table-col-price">$ {price}</td>

                <td className="summary__table-col-remove">
                  <button
                    className="summary__table-remove-button"
                    onClick={() => {
                      const relatedItemsIDs = _.filter(inventory, {
                        relatedTo: equip._id,
                      }).map((i) => i._id);

                      updateBookState({
                        bookedEquipment: bookedEquipment.filter(
                          (i) =>
                            i._id !== equip._id &&
                            !relatedItemsIDs.includes(i._id)
                        ),
                      });
                    }}
                  >
                    <img src="/img/delete.svg" alt="" />
                  </button>
                </td>
              </tr>
            );
          })}

          <tr className="summary__table-total-row">
            <td>Subtotal</td>
            <td></td>
            <td>$ {amount.toFixed(2)}</td>
            <td></td>
          </tr>

          <tr className="summary__table-total-row">
            <td>MA State Sales Tax</td>
            <td></td>
            <td>$ {tax.toFixed(2)}</td>
            <td></td>
          </tr>

          <tr className="summary__table-total-row">
            <td>Total</td>
            <td></td>
            <td>$ {totalAmount.toFixed(2)}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    );
  };
}

Summary.propTypes = {
  book: PropTypes.object.isRequired,
  updateBookState: PropTypes.func.isRequired,
};

const mapStateToProps = ({ book }) => ({
  book,
});

export default connect(mapStateToProps, { updateBookState })(Summary);
