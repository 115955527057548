import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { resetBookState } from 'actions/bookActions';

class Thanks extends Component {
  componentWillUnmount() {
    const { resetBookState } = this.props;

    resetBookState();
  }

  render() {
    const { book } = this.props;

    if (!book || !book.reservationNumber) {
      return <Redirect to="/" />;
    }

    return (
      <section className="thanks">
        <h1>THANK YOU</h1>
        <span>
          <label>Order ID</label>
          <strong>{book.reservationNumber}</strong>
          <label>Date</label>
          <strong>{book.date}</strong>
        </span>
        <label>
          Thank you for choosing the Beach Butler! Please use your Flag ID to
          find your spot on the beach. A Beach Butler will check in with you
          soon after your arrival. Enjoy your beach day to the fullest!
        </label>

        <Link to="/">Back to Site</Link>
      </section>
    );
  }
}

const mapStateToProps = ({ book }) => ({
  book
});

export default connect(
  mapStateToProps,
  { resetBookState }
)(Thanks);
