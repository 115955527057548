import axios from "axios";

const apiPoint = "/api/testimonials/";

export default {
  fetchList() {
    return axios
      .get(apiPoint)
      .then(res => Promise.resolve(res.data))
      .catch(err => {
        console.log(err);
        return Promise.resolve([]);
      });
  },
};
