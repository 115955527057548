export const UPDATE_BOOK_STATE = 'UPDATE_BOOK_STATE';
export const SELECT_BEACH = 'SELECT_BEACH';
export const RESET_BOOK_STATE = 'RESET_BOOK_STATE';
export const SET_BOOK_STATE_FOR_ADDITIONAL_ORDER =
  'SET_BOOK_STATE_FOR_ADDITIONAL_ORDER';

export const SHOW_INFO_MODAL = 'SHOW_INFO_MODAL';
export const SHOW_PROMPT_MODAL = 'SHOW_PROMPT_MODAL';
export const SHOW_CONFIRM_MODAL = 'SHOW_CONFIRM_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
