import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <footer className="website-footer">
        <div>
          <span>Connect</span>

          <div className="social-links">
            <a
              href="https://www.facebook.com/mybeachbutler/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src="img/facebook@2x.png" alt="facebook" />
            </a>

            <a
              href="https://www.instagram.com/mybeachbutler/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src="img/instagram-logo@2x.png" alt="instagram" />
            </a>
          </div>
        </div>

        <Link to="/contact-us">Contact us</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </footer>
    );
  }
}

export default Footer;
