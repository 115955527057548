import {
  SHOW_INFO_MODAL,
  SHOW_PROMPT_MODAL,
  CLOSE_MODAL,
  SHOW_CONFIRM_MODAL
} from 'actions/types';

const filter = 'modal';

export const showInfo = payload => ({
  filter,
  type: SHOW_INFO_MODAL,
  payload
});

export const closeModal = () => ({
  filter,
  type: CLOSE_MODAL
});

export const showPrompt = params => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      filter,
      type: SHOW_PROMPT_MODAL,
      payload: { ...params, cb: resolve }
    });
  });

export const showConfirm = params => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      filter,
      type: SHOW_CONFIRM_MODAL,
      payload: { ...params, cb: resolve }
    });
  });
