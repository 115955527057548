import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { updateBookState } from "actions/bookActions";
import { showInfo } from "actions/modalActions";
import API from "api";

function generateMarker(map, coords) {
  return new window.google.maps.Marker({
    position: coords,
    map,
    icon: "/img/pin.png",
  });
}

function generateCircle(map, coords) {
  return new window.google.maps.Circle({
    zIndex: 10,
    strokeColor: "#ffffff",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#ffffff",
    fillOpacity: 0.7,
    map,
    center: coords,
    radius: 7,
  });
}

class MapPin extends Component {
  state = {};

  componentDidMount() {
    const { beachId, showInfo } = this.props;

    API.beaches.getById(beachId).then(({ polygons, mapInitialViewBounds }) => {
      if (!polygons || !polygons.length) {
        return showInfo({
          html: "We are sorry, there are no available places to spot",
        });
      }

      this.setState({ polygons, mapInitialViewBounds }, this.initMap);
    });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (prevProps.location !== location) {
      this.drawPin();
    }
  }

  drawPin = () => {
    const { location } = this.props;
    const { marker, circle } = this.state;

    marker && marker.setMap(null);
    circle && circle.setMap(null);

    const [lat, lng] = location.replace(/[\s()]/g, "").split(",");

    const coords =
      location &&
      new window.google.maps.LatLng(parseFloat(lat), parseFloat(lng));

    this.setState({
      marker: generateMarker(this.map, coords),
      circle: generateCircle(this.map, coords),
    });
  };

  initMap() {
    const { updateBookState, location, showInfo, isAdditionalOrder } =
      this.props;
    const { google } = window;

    const { polygons, mapInitialViewBounds } = this.state;

    const map = new google.maps.Map(document.getElementById("map"), {
      draggable: !isAdditionalOrder,
      // disableDefaultUI: isAdditionalOrder,
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.HYBRID,
      mapTypeControlOptions: {
        mapTypeIds: [],
      },
    });

    const bounds = new google.maps.LatLngBounds();

    polygons.forEach((polygon) => {
      const coordinatesObject = polygon.map((e, i) => {
        const gmCoords = new google.maps.LatLng(e[0], e[1]);
        bounds.extend(gmCoords);
        return gmCoords;

        // return { lat: e[0], lng: e[1] };
      });

      const Polygon = new google.maps.Polygon({
        paths: coordinatesObject,
        strokeColor: "#FF007F",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF007F",
        fillOpacity: 0.35,
      });

      Polygon.setMap(map);

      if (!isAdditionalOrder) {
        Polygon.addListener("click", function ({ latLng }) {
          this.lastCords = latLng;

          if (map.zoom < 19) {
            map.setZoom(19);
            map.setCenter(latLng);
            return;
          }

          updateBookState({ location: latLng.toString() });
        });
      }
    });

    if (location) {
      const [lat, lng] = location.replace(/[\s()]/g, "").split(",");
      map.setCenter(new google.maps.LatLng(lat, lng));
    } else {
      try {
        const newBounds = new google.maps.LatLngBounds();
        mapInitialViewBounds.split(";").forEach((coords) => {
          const [lat, lng] = coords.split(",");
          const gmCoords = new google.maps.LatLng(
            Number(lat.trim()),
            Number(lng.trim())
          );
          newBounds.extend(gmCoords);
        });
        map.fitBounds(newBounds);
      } catch (err) {
        map.setCenter(bounds.getCenter());
      }
    }

    if (!isAdditionalOrder) {
      map.addListener("click", function (event) {
        showInfo({
          html: "The spot you have chosen is out of range. Please select a spot within the pink shaded area.",
        });
        map.setCenter(this.lastCords);
      });
    }

    this.map = map;

    if (location) {
      this.drawPin();
    }
  }

  render() {
    const { location } = this.props;

    return (
      <section id="mapPin">
        <div className="text-wrapper">
          <h2 className="booking__title">Pick Your Spot</h2>

          <div className="booking__description">
            <p>
              All locations are approximate and not guaranteed. Our Beach
              Butlers will do their best to set up your spot as close to your
              desired location as possible. Your spot will be marked with a
              numbered flag.
            </p>
            <p>
              Use the map below to let us know approximately where you want to
              sit. Simply pick your preferred location as you zoom in within the
              pink shaded area by “dropping” the blue pin.
            </p>
          </div>
        </div>

        <div id="map"></div>

        <div className="booking-controls">
          <div className="booking-controls__inner">
            <button
              className="booking-controls__back"
              onClick={() => {
                this.props.changeStep(1);
              }}
            >
              Back to Dates
            </button>

            <button
              className="booking-controls__submit"
              disabled={!location}
              onClick={() => {
                this.props.changeStep(3);
              }}
            >
              Next
            </button>
          </div>
        </div>
      </section>
    );
  }
}

MapPin.propTypes = {
  beachId: PropTypes.string.isRequired,
  location: PropTypes.string,
  showInfo: PropTypes.func.isRequired,
  updateBookState: PropTypes.func.isRequired,
  isAdditionalOrder: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ book }) => ({
  beachId: book.beach._id,
  location: book.location,
  isAdditionalOrder: !!book.additionalOrder,
});

export default connect(mapStateToProps, { updateBookState, showInfo })(MapPin);
