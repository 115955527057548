import formatMSToTimeString from './formatMSToTimeString';

export default (from, to, interval) => {
  const timeOptions = [];

  for (let time = from; time <= to; time += interval) {
    timeOptions.push(formatMSToTimeString(time));
  }

  return timeOptions;
};
