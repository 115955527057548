import moment from "moment";

export default timeString => {
  if (!timeString) return 0;

  const [time, ampm] = timeString.split(" ");
  let [hours, minutes] = time.split(":");

  minutes = +minutes;
  hours = +hours;

  if (ampm === "PM" && hours !== 12) {
    hours += 12;
  }

  if (ampm === "AM" && hours === 12) {
    hours = 0;
  }

  const duration = moment.duration({
    hours,
    minutes
  });

  return duration.asMilliseconds();
};
