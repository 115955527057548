import {
  SHOW_INFO_MODAL,
  CLOSE_MODAL,
  SHOW_PROMPT_MODAL,
  SHOW_CONFIRM_MODAL
} from 'actions/types';

const initialState = {};

const filter = 'modal';

export default (state = initialState, action) => {
  if (filter !== action.filter) return state;

  switch (action.type) {
    case SHOW_INFO_MODAL:
      return {
        ...state,
        info: action.payload
      };
    case SHOW_PROMPT_MODAL:
      return {
        ...state,
        prompt: action.payload
      };
    case SHOW_CONFIRM_MODAL:
      return {
        ...state,
        confirm: action.payload
      };
    case CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
};
